import type { UseFetchOptions } from 'nuxt/app';

export function useApiFetch<T>(path: string, options: UseFetchOptions<T> = {}) {
    let headers: any = {};
    const { $api } = useNuxtApp();
    const csrfToken = useCookie('XSRF-TOKEN');
    const { token } = useAuth();

    if (csrfToken.value) {
        headers['X-XSRF-TOKEN'] = csrfToken.value as string;
    }

    headers = {
        ...headers,
        ...useRequestHeaders(['cookie']),
    };

    return useFetch($api(path), {
        key: useGenVueKey(),
        credentials: 'include',
        watch: false,
        ...options,
        headers: {
            ...headers,
            ...options?.headers,
            authorization: token.value,
        },
    });
}
